<template>
	<div class="container">
		<div class="row">
			<div class="col-12 text-center pb-4">
				<h2>{{ $t('message.mp_title3') }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 pb-2">
				<div class="card">
					<a :href="setGoogleLocation(json.pick_ups[0].map_url)" target="_blank">
						<img class="card-img-top"
							 :src="require(`@/assets/images/${json.pick_ups[0].img_src}`)"
							 alt=""></a>
					<div class="card-body">
						<h5 class="card-title">
							{{ $t('message.locations.location1') }}
						</h5>
						<p class="card-text"></p>
						<a :href="'tel:' + i" class="btn btn-secondary mr-2 mb-2" v-for="i in json.pick_ups[0].contact" @click="gtmClick('lead_phone', $t('message.locations.location1'))">
							<i class="fas fa-phone"></i> {{ i }}</a>
						<a :href="setGoogleLocation(json.pick_ups[0].direction_url)" class="btn btn-secondary mb-2"
						   target="_blank" @click="gtmClick('lead_map', $t('message.locations.location1'))"><i
							class="fas fa-directions"></i>
							{{ $t('message.address_directions') }}</a>
					</div>
					<p style="font-size:0.75rem;font-weight:400;margin: 15px 25px;">{{ $t('message.cost') }}</p>
				</div>
			</div>
			<div class="col-12 col-md-4 pb-2">
				<div class="card">
					<a :href="setGoogleLocation(json.pick_ups[1].map_url)" target="_blank">
						<img class="card-img-top"
							 :src="require(`@/assets/images/${json.pick_ups[1].img_src}`)"
							 alt=""></a>
					<div class="card-body">
						<h5 class="card-title">
							{{ $t('message.locations.location2') }}
						</h5>
						<p class="card-text"></p>
						<a :href="'tel:' + i" class="btn btn-secondary mr-2 mb-2" v-for="i in json.pick_ups[1].contact" @click="gtmClick('lead_phone', $t('message.locations.location2'))">
							<i class="fas fa-phone"></i> {{ i }}</a>
						<a :href="setGoogleLocation(json.pick_ups[1].direction_url)" class="btn btn-secondary mb-2"
						   target="_blank" @click="gtmClick('lead_map', $t('message.locations.location2'))"><i
							class="fas fa-directions"></i>
							{{ $t('message.address_directions') }}</a>
					</div>
					<p style="font-size:0.75rem;font-weight:400;margin: 15px 25px;">{{ $t('message.cost') }}</p>
				</div>
			</div>
			<div class="col-12 col-md-4 pb-2">
				<div class="card">
					<a :href="setGoogleLocation(json.pick_ups[2].map_url)" target="_blank">
						<img class="card-img-top"
							 :src="require(`@/assets/images/${json.pick_ups[2].img_src}`)"
							 alt=""></a>
					<div class="card-body">
						<h5 class="card-title">
							{{ $t('message.locations.location3') }}
						</h5>
						<p class="card-text"></p>
						<a :href="'tel:' + i" class="btn btn-secondary mr-2 mb-2" v-for="i in json.pick_ups[2].contact" @click="gtmClick('lead_phone', $t('message.locations.location3'))">
							<i class="fas fa-phone"></i> {{ i }}</a>
						<a :href="setGoogleLocation(json.pick_ups[2].direction_url)" class="btn btn-secondary mb-2"
						   target="_blank" @click="gtmClick('lead_map', $t('message.locations.location3'))"><i
							class="fas fa-directions"></i>
							{{ $t('message.address_directions') }}</a>
					</div>
					<p style="font-size:0.75rem;font-weight:400;margin: 15px 25px;">{{ $t('message.cost') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import json from '../../website-data.json';

export default {
	name       : 'Location',
	components : {},
	data       : function () {
		return {
			json : json,
			lang : this.$route.params.lang
		};
	},
	computed   : {
		path() {
			return this.$store.getters.setLocale;
		}
	},
	methods    : {
		setGoogleLocation(link) {
			if (this.path === 'en') {
				return link.replace(/(\.pt)/g, `.com`);
			}
			return link.replace(/(\.pt)/g, `.${this.path}`);
		},
		gtmClick(category, location) {
			if (this.$gtm.enabled()) {
				window.dataLayer?.push({
					event          : 'contact_click',
					category       : category,
					action         : 'pickup',
					label          : location,
					value          : 0,
					noninteraction : false // Optional
				});
			}
		}
	}
};
</script>
